<template>

  <div v-if="isShow">
    <Loading/>
  </div>

  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("news") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/news">
            {{ $t("news") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="minHeight middle common-padding-100">
    <div class="news-list">
      <ul>
        <li v-for="item in list" :key="item.id" class="common-padding-half">
          <a :href="item.pdf" target="_blank" >
            <img :src="item.cover">
            <h5>{{item.date}}</h5>
            <p><b>{{item.title}}</b> </p>
            <div class="jt">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </a>
        </li>
        <li></li>
      </ul>
    </div>

    <Pagination
        :current-page="currentPage"
        :total-pages="totalPages"
        :show-pagination="showPagination"
        @goto-page="onPageChange"
    />

  </div>
  <FooterInquiry></FooterInquiry>
</template>

<script>
import { ref } from 'vue'
import FooterInquiry from "@/components/FooterInquiry";
import Loading from "@/components/loading";
import Pagination from "@/components/Pagination";
import {newsList} from "@/network/main";


export default {
  name: "News",
  components:{
    FooterInquiry,
    Loading,
    Pagination
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta
    }
  },
  metaInfo () {
    return {
      title:"Catalogs - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.seo
        }
      ],
    }
  },
  data(){
    return{
      isShow:true,
      banner:'',
      list:'',
      seo:'',
      currentPage: 1,
      totalPages: 1,
      showPagination: true,
    }
  },
  created() {
    this.getInfo(this.currentPage)
  },
  methods: {
    getInfo(page) {
      newsList(page).then(res =>{
        this.isShow = false;
        this.banner = res.data.data.banner;
        this.list = res.data.data.list;
        this.seo = res.data.data.seo;
        this.totalPages = res.data.data.last_page;
        this.showPagination = this.totalPages > 1;
      })
    },
    //分页
    onPageChange(page) {
      this.currentPage = page;
      this.getInfo(this.currentPage);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  }
}
</script>

<style scoped>

</style>